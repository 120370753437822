.rating_star{
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-icons{
        color: rgba(254, 243, 199, 1) !important;
        display: flex;
        align-items: center;
        .star-svg{
            width: 28px;
            height: 28px;
            flex-shrink: 0;
        }
    }
    .filled-icons{
        color: rgb(255, 188, 11) !important;
        color: #fff;
        display: flex;
        align-items: center;
        .star-svg{
            width: 28px;
            height: 28px;
            flex-shrink: 0;
        }
    }
}
.rating_star_wraper{
    > span{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}